import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'
import React from 'react'

import styles from './title.module.scss'

enum TitleSize {
  XSmall = 'XSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge'
}

enum TitleElement {
  Div = 'div',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  SPAN = 'span'
}

enum TitleDirection {
  Row = 'row',
  Column = 'column'
}

interface TitleProps extends PropsWithChildren {
  id?: string
  title?: string
  subTitle?: string
  text?: string
  link?: string
  size?: string | TitleSize
  desktopSize?: string | TitleSize // only for overrides
  className?: string
  darkMode?: boolean
  tag?: string | TitleElement,
  ariaLabel?: string
  isExternal?: boolean
  direction?: TitleDirection
}

const Title: FunctionComponent<TitleProps> = (
  {
    id,
    title,
    subTitle,
    text,
    link,
    size,
    desktopSize,
    children,
    className,
    darkMode,
    tag = TitleElement.Div,
    ariaLabel,
    isExternal,
    direction = TitleDirection.Row,
  }
) => {
  if( !title && !text && !children && !subTitle)  {
    return null
  }

  const isAnchor = () => {
    return link?.startsWith('#')
  }

  const TagElement = tag
  const LinkElement = link && !isAnchor() ? 'a' : React.Fragment

  return <TagElement
    id={id}
    className={clsx(
      styles.title,
      link && styles.hasLink,
      size === TitleSize.XSmall && styles.titleXSmall,
      size === TitleSize.Small && styles.titleSmall,
      size === TitleSize.Medium && styles.titleMedium,
      size === TitleSize.Large && styles.titleLarge,
      size === TitleSize.XLarge && styles.titleXLarge,
      desktopSize === TitleSize.Small && styles.titleDesktopSmall,
      desktopSize === TitleSize.Medium && styles.titleDesktopMedium,
      desktopSize === TitleSize.Large && styles.titleDesktopLarge,
      desktopSize === TitleSize.XLarge && styles.titleDesktopXLarge,
      className,
      isAnchor() && styles.anchor,
      direction === TitleDirection.Column && styles.titleColumn,
      darkMode && styles.titleDark,
    )}
    data-id={isAnchor() ? link?.slice(1) : undefined}
    aria-label={ariaLabel}
    title={ariaLabel}
  >
    <LinkElement
    href={!isAnchor() ? link : undefined}
    target={link && isExternal ? '_blank' : undefined}
  >
    {
      text
        ? <span dangerouslySetInnerHTML={{ __html: text }} />
        : title || children
    }
    {subTitle && <span className={styles.subTitle}>{direction === TitleDirection.Row ? <>&nbsp;</> : null}{subTitle}</span>}
    </LinkElement>
  </TagElement>
}

export default Title

export {
  TitleDirection,
  TitleElement,
  TitleSize,
}

export type {
  TitleProps,
}
