import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'

import styles from './hidden.module.scss'

interface HiddenProps extends PropsWithChildren {
  className?: string
  tag?: string
}

const Hidden: FunctionComponent<HiddenProps> = (
  {
    children,
    tag = 'span',
    className
  }
) => {
  const TagElement = tag

  return <TagElement
    className={clsx(
      styles.hidden,
      className
    )}
  >
    {children}
  </TagElement>
}

export default Hidden

export type {
  HiddenProps
}
