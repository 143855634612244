import Advertisement, { AdPosition } from './advertisement'
import AdvertisementGroup from './advertisement-group'

declare global {
  interface Window {
    advertisingData: {
      adSlots: AdSlot[],
      adUnit: string[],
      pageType: string[],
      targeting: string[]
    }
  }
}

interface AdSlot {
  adType?: string,
  adId: string,
  adElement: Element,
  adGroup: string | boolean
}

function getAdId(ad: Element, position = AdPosition.Bottom) {
  return `${position}${getIdPerType(ad, position)}`
}

const getIdPerType = (ad: any, position?: string) => {
  if (position === AdPosition.Top) {
    return '1'
  }

  const ads = Array.from(document.querySelectorAll(`.vrt-advertising__ad[data-ad-type='${ad.dataset.adType}']`))
    .filter(ad => {
      // exclude t's
      return (ad as HTMLElement).dataset.adPosition !== AdPosition.Top
    })
    .sort((a, b) => {
      // put cba's in sidebar last
      if ((a as HTMLElement).dataset.adType === 'cba' && a.closest('.vrt-aside--head,.vrt-aside--body,.vrt-aside--footer') !== null) return 1
      else if ((b as HTMLElement).dataset.adType === 'cba' && b.closest('.vrt-aside--head,.vrt-aside--body,.vrt-aside--footer') !== null) return -1
      else return 0
    })
  return ads.findIndex(thisAd => thisAd === ad) + 1
}

const initAds = () => {
  if (typeof window === 'undefined')
    return

  window.advertisingData = window.advertisingData || {}

  function provideIds() {
    window.advertisingData.adSlots.forEach(slot => {
      slot.adElement.setAttribute('id', `ad_${slot.adType}-${slot.adId}`)
      // slot.adElement.innerHTML = `<b style="color:red">ad_${slot.adType}-${slot.adId}</b>` // for testing
    })
  }

  if (window.advertisingData) {
    if (!window.advertisingData.adSlots) window.advertisingData.adSlots = []

    const ads = Array.from(
      document.querySelectorAll('.vrt-advertising__ad')
    )
      .filter(ad => window.getComputedStyle(ad).display !== 'none')

    window.advertisingData.adSlots = ads.map((ad) => {
      return {
        adType: (ad as HTMLElement).dataset.adType,
        adId: getAdId(ad, ((ad as HTMLElement).dataset.adPosition) as AdPosition),
        adElement: ad,
        adGroup: (ad as HTMLElement).dataset.adType === 'cba' ? 'cba' : false
      }
    })

    provideIds()
  }
}

type AddUnitArguments = {
  adUnit?: string | string[],
  pageType?: string,
  targeting?: string[]
}

const addAdUnit = (
  addUnitArguments: AddUnitArguments = {}
) => {
  if (typeof window === 'undefined')
    return

  const {
    adUnit,
    pageType,
    targeting = []
  } = addUnitArguments

  window.advertisingData = window.advertisingData || {}

  if (!window.advertisingData) {
    return
  }

  if (adUnit) {
    const adUnitArray = Array.isArray(adUnit) ? adUnit : [adUnit]

    // adobe launch prepends brand sporza
    const brandlessAdUnitArray = adUnitArray.map(item => item.replace('sporza/', ''))

    window.advertisingData.adUnit = brandlessAdUnitArray
  }

  window.advertisingData.pageType = Array.isArray(pageType) ? pageType : [pageType]
  window.advertisingData.targeting = targeting
}

export {
  addAdUnit,
  initAds,
  getAdId,
  Advertisement,
  AdvertisementGroup
}

export type { AdvertisementProps } from './advertisement'
