import type { FunctionComponent } from 'react'

import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import styles from './advertisement.module.scss'
import { getAdId } from './index'

enum AdvertisementType {
  LargeLeaderboard = 'np_leaderboard',
  Halfpage = 'halfpage',
  Rectangle = 'rectangle',
  MediumRectangle = 'medium_rectangle',
  Cba = 'cba'
}

enum AdPosition {
  Top = 't',
  Bottom = 'b'
}

interface AdvertisementProps {
  adType?: string | AdvertisementType
  setAdOffset?: object
  position?: AdPosition
}

const Advertisement: FunctionComponent<AdvertisementProps> = ({ adType, setAdOffset, position = AdPosition.Bottom }) => {
  const adRef = useRef<null | HTMLDivElement>(null)
  const [id, setId] = useState<string>()

  useEffect(() => {
    if (!adRef.current) return
    const resizeObserver = new ResizeObserver(() => {
      const height = adRef?.current?.clientHeight || 0
      if (typeof setAdOffset === 'function') setAdOffset(height + 1)
    })
    resizeObserver.observe(adRef.current)

    setId(`ad_${adType}-${getAdId(adRef.current, position)}`)

    return () => resizeObserver.disconnect()
  }, [adRef])

  return adType
    ? <div
      ref={adRef}
      id={id}
      className={clsx(
        'vrt-advertising__ad',
        styles.placeholder,
        adType === AdvertisementType.LargeLeaderboard && styles.placeholderLargeLeaderboard
      )}
      data-ad-type={adType}
      data-ad-position={position}
    />
    : null
}

export default Advertisement

export {
  AdvertisementType,
  AdPosition
}

export type {
  AdvertisementProps
}
